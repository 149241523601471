import dayjs from "dayjs";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useApp } from "../../../../providers/AppContext";
import PayWithPixCode from "./components/PayWithPixCode/PayWithPixCode";
import PayWithPix from "./components/PayWithPix/PayWithPix";

import info from "../../../../assets/icons/info.png";
import arrow_forward from "../../../../assets/icons/arrow-left-1.png";

function Pix() {
	const { setPaymentMethod, paymentMethods, order, getOrder, setRetry } = useApp();
	const { code } = useParams();
	const [payWithPix, setPayWithPix] = useState(true);
	const [isCode, setIsCode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorResponse, setErrorResponse] = useState(false);
	const [windowWidth, setWindowWidth] = useState(false);

	const setStop = useRef(false);

	useEffect(() => {
		setWindowWidth(window.innerWidth >= 992);

		window.addEventListener("resize", () => {
			setWindowWidth(window.innerWidth >= 992);
		});

		if (order?.paymentMethod?.paymentStatus === 13) {
			getOrder(code);
		}

		return () => {
			setStop.current = true;
		};
	}, []);

	const requestPixCode = async () => {
		setLoading(true);
		let payment = paymentMethods.find((payment) => payment.type === 6);

		const paymentInfo = {
			paymentMethod: payment,
		};
		const returnPayment = await setPaymentMethod(code, paymentInfo);

		if (returnPayment.status === 200) {
			await setPayWithPix(!payWithPix);
			setLoading(false);

			setRetry(false);

			if (window.innerWidth >= 992) {
				setIsCode(true);
			}

			return;
		}
		setLoading(false);

		setErrorResponse(true);
	};

	const mountPixScreen = () => {
		return (
			<>
				<div className="col-12">
					<p>
						Atenção! Siga as instruções abaixo para realizar seu pagamento PIX. Após a transferência, seu
						pagamento será aprovado na hora! É rápido, prático e seguro! :-)
					</p>
					<p>
						<span className="pix__bold__text">Vencimento:</span>{" "}
						{dayjs(order?.paymentMethod?.expirationDate).format("DD/MM/YYYY HH:mm:ss")}
					</p>
					<p>
						<span className="pix__bold__text">Valor da transferência:</span>{" "}
						{order?.totalAmount?.toLocaleString("pt-br", {
							style: "currency",
							currency: "BRL",
						})}
					</p>
				</div>
				{isCode && windowWidth ? <PayWithPixCode /> : <PayWithPix />}
				<div className="col-12">
					<div className="alert alert-warning mb-0" role="alert">
						<div className="d-flex">
							<div className="flex-shrink-0" style={{ color: "#FDAC41" }}>
								<img className="png-icons__24px" src={info} alt="" />
							</div>
							<div className="flex-grow-1 ms-3">
								<p className="mb-0">
									Atenção! O código PIX criado tem validade até{" "}
									{dayjs(order?.paymentMethod?.expirationDate).format("DD/MM/YYYY HH:mm:ss")}.{" "}
									<strong>Após o vencimento</strong>, seu pedido será{" "}
									<strong>cancelado automaticamente</strong>. <br />
									<br />O pagamento por PIX só funcionará no banco, instituição financeira ou central de
									pagamentos em que você possua uma Chave PIX cadastrada!
								</p>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className="row g-3 payment__form">
			<div className="col-12 d-grid gap-2 mx-auto mt-4">
				{payWithPix && order?.paymentMethod?.paymentStatus !== 13 ? (
					<>
						<button
							type="button"
							disabled={loading}
							className="btn btn-dark"
							onClick={() => requestPixCode()}
						>
							<span className="d-flex align-items-center justify-content-center">
								{loading && (
									<span
										className="spinner-border spinner-border-sm mr-3"
										role="status"
										aria-hidden="true"
										style={{ marginRight: "0.5em" }}
									></span>
								)}
								<span>
									Pagar (
									{order?.totalAmount?.toLocaleString("pt-br", {
										style: "currency",
										currency: "BRL",
									})}
									) com PIX
								</span>
								<img className="png-icons__24px" src={arrow_forward} alt="" />
							</span>
						</button>
						{isCode && mountPixScreen()}
						{errorResponse && (
							<div className="alert alert-danger p-3 mb-0 mt-2" role="alert">
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<img className="png-icons__24px" src={info} alt="" />
									</div>
									<div className="flex-grow-1 ms-3 d-flex align-items-center">
										<p className="mb-0">
											Atenção! Parece que houve um problema ao realizar a sua solicitação. Tente novamente!
										</p>
									</div>
								</div>
							</div>
						)}
					</>
				) : (
					<>
						{mountPixScreen()}
						<span className="col-12 d-grid gap-2 mx-auto mt-3">
							<button
								type="button"
								className="btn btn-sm desktop--show btn-outline-secondary pay__cel__button"
								onClick={() => setIsCode(!isCode)}
							>
								{isCode ? "Pagar sem celular" : "Pagar com o celular"}
							</button>
						</span>
					</>
				)}
			</div>
		</div>
	);
}

export default Pix;
